<template>
  <div>
    <vx-input v-model="value" multiple type="autocomplete" :items="items" item-text="name" item-value="email" />
    <!-- <vx-list :list-props="{ dense: true }" draggable :items="items" item-title="name" item-subtitle="email"></vx-list> -->
  </div>
</template>

<script>
export default {
  name: 'Test',

  data() {
    return {
      value: [
        'curabitur.consequat@hotmail.com',
        'arcu@hotmail.net',
        'a.auctor@icloud.org',
        'justo.nec.ante@google.com',
        'tincidunt.tempus@yahoo.couk',
      ],
      items: [
        {
          name: 'Moses Bray',
          phone: '1-756-482-5176',
          country: 'Brazil',
          email: 'curabitur.consequat@hotmail.com',
        },
        {
          name: 'Kenneth Valentine',
          phone: '1-667-838-2321',
          country: 'South Korea',
          email: 'arcu@hotmail.net',
        },
        {
          name: 'Anthony Mclean',
          phone: '(573) 354-2885',
          country: 'United States',
          email: 'a.auctor@icloud.org',
        },
        {
          name: 'Gabriel Flowers',
          phone: '1-463-272-4855',
          country: 'Pakistan',
          email: 'justo.nec.ante@google.com',
        },
        {
          name: 'Chancellor Rowe',
          phone: '(451) 832-6037',
          country: 'Pakistan',
          email: 'tincidunt.tempus@yahoo.couk',
        },
      ],
    };
  },
};
</script>
